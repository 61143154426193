import { Container } from "react-bootstrap"
import Particle from "../Particle"

function Service() {
  return (
    <Container fluid className="about-section">
        <Particle />
        <Container>
        <h1>Hola</h1>
        </Container>
    </Container>

  )
}

export default Service
